.headerBck {
    background-image: linear-gradient(to right, #122b41 0%, #0a101e 100%);
    position: fixed;
    top: 0;
    z-index: 9;
    width: 100%;
}

body {
    overflow: hidden;
}

.loginDetailes {
    background: #fff;
    padding: 10px;
    position: fixed;
    right: 0;
    top: 10px;
    z-index: 9999;
    height: 100%;
}

.rowHeaders {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5px;
    position: sticky;
    top: 0;
    z-index: 999999;
}

.dashBoardText {
    color: #fff;
    font-size: 17px;
}

.hrPro {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 5px 0 0;
    border: 2px solid #fff;
}

.hrPro img {
    max-width: 100%;
}

.unames {
    background: none !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none !important;
}

.dropItem {
    width: 100%;
}

.dropItem a {
    border-bottom: 1px solid #e6e6e6;
    padding: 8px;
}

.dropItem {
    padding: 0;
}

.hrmodulesBack {
    background: url("../../../../public/imgs/hrImgs/hrBgs.png");
    background-size: cover;
    min-height: 100vh;
    width: 100%;
}

.onImg {
    background-image: linear-gradient(to right, #105bef8a 0%, #ff3fc469 100%);
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.cricle_box {
    max-width: 800px;
    border-radius: 50%;
    position: relative;
    margin: 10px;
}

.cricle_box img {
    width: 100%;
}

.criclBack {
    position: absolute;
    width: 17%;
    text-align: center;
}

.criclBack a {
    text-decoration: none !important;
}

.iconsBack:hover {
    border: 2px solid #00a0fd;
    transition: 1s;
    top: -8px;

}

.iconsBack {
    width: 82%;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin: 0 auto;
    flex-wrap: wrap;
    border: 2px solid #ffffff;
    transition: 1s;
    padding: 0.5vw;
    background: #fff;
}

.iconsBack img {
    max-width: 100%;
}

.textDiv {
    font-size: max(0.7vw, 12px);
    color: #fff;
    margin: 5px 0;
}

.crl1 {
    left: 42.5%;
    top: 8.4%;
}

.crl2 {
    left: 67.5%;
    top: 18.4%;
}

.crl3 {
    left: 77.1%;
    top: 43.3%;
}

.crl4 {
    left: 64.4%;
    top: 65.3%;
}

.crl5 {
    left: 42.4%;
    top: 75.2%;
}

.crl6 {
    left: 19.1%;
    top: 65.2%;
}

.crl7 {
    left: 8.1%;
    top: 43.2%;
}

.crl8 {
    left: 17.4%;
    top: 18.2%;
}

.loader {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    position: absolute;
    top: 0%;
    left: 0%;
    opacity: 0.5;
}

.loader .round {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 4px dashed #333;
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
    top: 50%;
    left: 50%;
    animation: rotate-first 10s infinite ease-in-out;
    opacity: 0.1;
}

.loader .round:nth-of-type(2) {
    width: 33%;
    height: 33%;
    animation: rotate-second 4s infinite ease-in-out;
    opacity: 0.3;
}

.loader .round:nth-of-type(3) {
    width: 60%;
    height: 60%;
    animation: rotate-third 4s infinite ease-in-out;
}

@keyframes rotate-first {
    0% {
        border-color: #EA2027;
        transform: translateX(-50%) translateY(-50%) rotate(0deg);
    }

    100% {
        border-color: #ffffff;
        transform: translateX(-50%) translateY(-50%) rotate(360deg);
    }
}

@keyframes rotate-second {
    0% {
        border-color: #009432;
        transform: translateX(-50%) translateY(-50%) rotate(0deg);
    }

    100% {
        border-color: #0652DD;
        transform: translateX(-50%) translateY(-50%) rotate(-360deg);
    }
}

@keyframes rotate-third {
    0% {
        border-color: #065dfd;
        transform: translateX(-50%) translateY(-50%) rotate(0deg);
    }

    100% {
        border-color: #EA2027;
        transform: translateX(-50%) translateY(-50%) rotate(360deg);
    }
}

.hovicon {
    display: inline-block;
    border-radius: 50%;
    text-align: center;
    position: relative;
    text-decoration: none;
    z-index: 1;
    width: 82%;
}

.hovicon img {
    width: 100%;
    border-radius: 50%;
}

.hovicon.small {
    font-size: 20px;
    line-height: 45px;
    width: 45px;
    height: 45px;
    margin: 7px;
}

.hovicon.mini {
    font-size: 15px;
    line-height: 32px;
    width: 30px;
    height: 30px;
    margin: 7px;
}

.hovicon.auto-width {
    width: auto;
    height: auto;
    padding: 15px;
}

.hovicon:after {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    content: '';
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

.hovicon:before {
    speak: none;
    font-size: 48px;
    line-height: 90px;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    display: block;
    -webkit-font-smoothing: antialiased;
}

/* Effect 8 */
.hovicon.effect-8 {
    background: rgb(255, 255, 255);
    -webkit-transition: -webkit-transform ease-out 0.1s, background 0.2s;
    -moz-transition: -moz-transform ease-out 0.1s, background 0.2s;
    transition: transform ease-out 0.1s, background 0.2s;
    padding: 5px;
}

.hovicon.effect-8:after {
    top: 0;
    left: 0;
    padding: 0;
    z-index: -1;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1);
    opacity: 0;
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
}

.hovicon.effect-8:hover {
    background: rgba(255, 255, 255, 0.05);
    -webkit-transform: scale(0.93);
    -moz-transform: scale(0.93);
    -ms-transform: scale(0.93);
    transform: scale(0.93);
    color: #fff;
}

.hovicon.effect-8:hover i {
    color: #fff;
}

.hovicon.effect-8:hover:after {
    -webkit-animation: sonarEffect 1.3s ease-out 75ms;
    -moz-animation: sonarEffect 1.3s ease-out 75ms;
    animation: sonarEffect 1.3s ease-out 75ms;
}

@-webkit-keyframes sonarEffect {
    0% {
        opacity: 0.3;
    }

    40% {
        opacity: 0.5;
        box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #3851bc, 0 0 0 10px rgba(255, 255, 255, 0.5);
    }

    100% {
        box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #3851bc, 0 0 0 10px rgba(255, 255, 255, 0.5);
        -webkit-transform: scale(1.5);
        opacity: 0;
    }
}

@-moz-keyframes sonarEffect {
    0% {
        opacity: 0.3;
    }

    40% {
        opacity: 0.5;
        box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #3851bc, 0 0 0 10px rgba(255, 255, 255, 0.5);
    }

    100% {
        box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #3851bc, 0 0 0 10px rgba(255, 255, 255, 0.5);
        -moz-transform: scale(1.5);
        opacity: 0;
    }
}

@keyframes sonarEffect {
    0% {
        opacity: 0.3;
    }

    40% {
        opacity: 0.5;
        box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #3851bc, 0 0 0 10px rgba(255, 255, 255, 0.5);
    }

    100% {
        box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1), 0 0 10px 10px #3851bc, 0 0 0 10px rgba(255, 255, 255, 0.5);
        transform: scale(1.5);
        opacity: 0;
    }
}

.cursor {
    z-index: 1000;
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    opacity: 0;
    transform: translate(-50%, -50%);
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out,
        background-color 0.3s, width 0.3s, height 0.3s;
    width: 10px;
    height: 10px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;

    &.active {
        transform: translate(-50%, -50%) scale(1.5);
    }

    &.hidden {
        opacity: 0 !important;
    }
}

.hrleftMenu {
    position: fixed;
    top: 52px;
    left: 0;
    width: 260px;
    background: linear-gradient(to bottom, #D5DEE7 0%, #E8EBF2 50%, #E2E7ED 100%), linear-gradient(to bottom, rgba(0, 0, 0, 0.02) 50%, rgba(255, 255, 255, 0.02) 61%, rgba(0, 0, 0, 0.02) 73%), linear-gradient(33deg, rgba(255, 255, 255, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%);
    background-blend-mode: normal, color-burn;
    height: 96vh;
    overflow-y: auto;
    transition: 1s !important;
    z-index: 9;
}

.rightBox {
    margin-left: 260px;
    padding: 51px 0;
    background: #f3f3f3;
    min-height: 100vh;
    padding: 64px 17px 0 17px !important;
}

.clear {
    clear: both;
}

.iconsDiv {
    border: 1px solid #69696985;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    color: #000;
}

.button_WithIcons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #fff;
    padding: 10px 5px;
    border-bottom: 1px solid #9999994d;
    cursor: pointer;
    font-size: 14px;
    white-space: nowrap;
}


.hrleftMenu .accordion-button:hover {
    background-color: #c3c3c5;
}

.hrleftMenu .menubtn:hover {
    background-color: #c3c3c5;

}

.innerContBox {
    border: 1px solid #efefef;
    margin: 15px;
    margin-left: 231px;
    min-height: 89.5vh;
    transition: 1s;
    background-size: cover;
    background: #ebebeb;
}

.logHeader {
    background: #e1e1e1 !important;
    color: #0a0a0a;
    padding: 14px;
}

.Noti_logHeader {
    background: #6585a3 !important;
    color: #ffffff;
    padding: 8px;
}

.ankerTabs a {
    text-decoration: none;
    width: 100%;
    display: inline-block;
    padding: 10px 15px;
    background: #fcfcfc;
    color: #0a0a0a;
    border-bottom: 1px solid #b8b8b854 !important;
}

.ankerTabs a:hover {
    background: #e6e6e6;
}

.quickLink {
    font-size: 16px;
    color: #000;
    border-bottom: 1px solid #c9c9c9;
    padding: 2px 0;
}

.btnRightSide button {
    margin: 0 2px;
}

.hr_rowBtnSection {
    margin: 30px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-height: 60vh;
    overflow-y: auto;
    padding: 20px 5px;
}

.icons_right {
    border: 1px solid #cacaca;
    width: 35px;
    height: 35px;
    margin: 0 4px;
    color: #030303;
    padding: 4px;
    border-radius: 6px;
    cursor: pointer;
}

.mainBtn:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22) !important;
}

.mainBtn {
    border-radius: 10px;
    background-image: linear-gradient(to top, #f8f8f8 0%, #e2ebf0 100%);
    color: #707070;
    transition: 0.5s;
    white-space: nowrap;
}

.iconsCricle {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 3px solid #91141400;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    color: #fd0000;
}

.iconsCricle svg {
    width: 60px !important;
    height: 36px !important;
}

.innerButton {
    max-height: 100vh;
    overflow-y: auto;
}

.userDetails {
    display: flex;
}

.notiBox {
    position: relative;
    color: #cacaca;
    margin: 15px 20px 0 20px;
    cursor: pointer;
}

.nomberOfNoti {
    position: absolute;
    top: -5px;
    right: -5px;
    font-size: 12px;
    background: #fd0000;
    color: #fff;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.spiBlinker {
    position: absolute;
    right: 0;
    top: 0;
}

.toastFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-top: 1px solid #d1d1d1;
}

.backBtn a:hover {
    color: #ffffff;
}

.listDet {
    padding: 0;
    margin: 0;
}

.listDetH li {
    list-style: none;
    color: #fff;
    font-size: 14px;
    border-bottom: 1px solid #99999926;
    background: #444547;
    padding: 15px 10px;
    cursor: pointer;
}

.listDetH li:hover {
    background: #030303;
}

.hrleftMenu .accordion-button:hover {
    background: #c3c3c5;

}

.hrleftMenu .accordion-button {
    background: none;
    background-color: none;
    padding: 10px 5px;
    margin: 0;
    color: #fff !important;
    box-shadow: none !important;
    outline: none !important;
    width: 100%;
}

.hrleftMenu .accordion-item {
    background: none;
}

.hrleftMenu .accordion-button::after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
    margin-right: 0;
}

.bgClr .accordion-button {
    background-color: #dee4ec !important;
}

.hrLi li:hover {
    background: #2c2b2b !important;
}

.hrandroidItem {
    display: none;
}

.textHide {
    font-size: 15px;
    color: #000;
}

.white_bgs {
    background: #fff;
    padding: 15px;
    border-radius: 15px;
    background: url('../../../../public/imgs/hrImgs/hrAd.jpg');
    min-height: 91vh;
    background-size: cover;
    background-position: bottom;
}

.cricleBtns {
    width: 70px;
    height: 70px;
    border: 3px solid #ffffff;
    background-image: linear-gradient(-20deg, #2b5876 0%, #4e4376 100%);
    border-radius: 50%;
    position: absolute;
    left: -8px;
    top: -8px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fcfcfc;
}

.buttonBgs {
    position: relative;
    padding: 15px 10px 15px 70px;
    background-image: linear-gradient(-20deg, #2b5876 0%, #4e4376 100%);
    border: 3px solid #ffffff;
    width: 300px;
    border-radius: 50px;
    font-size: 16px;
    color: #fff;
    text-transform: uppercase;
    cursor: pointer;
    margin: 15px 6px;
}

.row_cricle_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.buttonBgs:hover {
    background-image: linear-gradient(-20deg, #185075 0%, #34256b 100%);
}

.buttonBgs:hover .cricleBtns {
    background-image: linear-gradient(-20deg, #06609c 0%, #270e81 100%);
}

.withOut_icn .accordion-button::after {
    display: none;
}

.UserList {
    background: #f1f1f1;
    padding: 5px;
}

.searchBoxAdmi:focus {
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid #056ffa;
}

.searchBoxAdmi {
    width: 96%;
    margin: 5px 10px;
}

.accordionBgClr .accordion-button {
    border: 1px solid #ebebeb;
    border-radius: 0px !important;
    padding: 11px !important;
    background-image: linear-gradient(0deg, #e8f5f8 0%, #b3b5b600 100%);
}

.accordionBgClr .accordion-button:focus {
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
}

.accordionBgClr .accordion-body {
    border: 1px solid #82defa;
    margin-top: -1px;
}

.actiove_Menu {
    background: #bdbdbd !important;
    border-bottom: 1px solid #343435 !important;
}

.mainBoxTabs {
    background: #f9f9f9;
    padding: 10px;
}

.rowTabs {
    /* display: flex;
    justify-content: flex-start; */
    border-bottom: 1px solid #adadad;
    padding: 10px;
    /* width: 100%; */
}

.rowTabs label {
    font-size: 14px !important;
    background-image: linear-gradient(0deg, #7de2fc00 0%, #81e0fb00 100%);
    padding: 0 5px;
    cursor: pointer;
    border: 1px solid #aaaaaa59;
    border-radius: 38px;
}

.rowTabs label:hover {
    background: #f5f2f2;
}

.inputTextFiled {
    margin: 15px 0;
}

.setPages {
    border-bottom: 1px solid #e7e7e7;
    margin: 15px 0;
}

.headerS {
    display: flex;
}

.alignTopSect {
    white-space: nowrap;
    background: #bdbdbd;
}

.alignTopSect th {
    padding: 2px 10px;
}

.hidePaddin td {
    padding: 0px 10px;
}

.tblsRols {
    white-space: nowrap;
    font-size: 14px;
}

.liActive {
    background: #222121 !important;
}

.listDetH {
    margin: 0;
    padding: 0;
}

.spacialFunc {
    border: 1px solid #eeeeee;
    padding: 0 10px 0 0;
    border-radius: 51px;
    margin: 0 10px 0 0 !important;
}





























@media screen and (max-width:1024px) {
    .hrleftMenu {
        left: -260px;
        transition: .4s;
        display: none;
    }

    .hrandroidItem {
        display: block;
    }

    .rightBox {
        margin: 0;
    }

}

.hrmenunuIcons {
    color: #fff;
}