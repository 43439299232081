@media (min-width:20px) and (max-width:767px) {
  .logoRs img {
    width: 197px;
    margin-left: 5px;
  }

  .navbar-toggler {
    background: #fff;
  }

  .welCome {
    font-size: 29px;
    color: #ffffff;
    font-weight: bold;
    margin: 15% 0 0 0;
    line-height: 36px;
  }

  .navbar-toggler-icon {
    display: inline-block;
    width: 19px;
    height: 22px;
  }

  .dearUser {
    font-size: 12px;
    color: #ffff;
    margin: 7px 0;
  }

  .loginBtn {
    padding: 1px 7px;
    border: 3px solid #f3f3f3;
    font-size: 15px;
  }

  .navBarright a {
    padding: 10px 25px !important;
    color: #cfcfcf !important;
    font-size: 15px;
    transition: 1s;
    border-bottom: 1px solid #f9f9f933;
  }
  .bannerCont img {
    max-width: 70%;
  }
  .bannerCont{
    text-align: center;
  }
  
  .fristCol {
    display: none;
  }
    .fristCol {
    display: none;
  }
  .innerCont {
    display: block;
  }

  .loginForm {
    background: #f7f7f7;
    padding: 35px 15px;
    border-radius: 10px;
  }

  .textTab {
    display: none;
  }

  .leftMenu {
    width: 55px !important;
  }

  .innerContBox {
    padding: 9px;
    margin: 4px;
    margin-left: 59px !important;
    min-height: 89vh;
  }

  .dashBoardText,.empldashBoardText {
    display: none;
  }

  .textDiv {
    font-size: max(0.7vw, 7px) !important;
  }

  .rowHeaders {
    justify-content: center !important;
  }

  .hr_rowBtnSection {
    margin: 0px !important;
    padding: 4px !important;
  }

  .icons_right {
    width: 24px;
    height: 25px;
  }

  .quicklinks {
    font-size: 13px !important;
  }
  .mainDivbtns{
    font-size: 13px;
  }

}

@media (min-width:768px) and (max-width:991px) {
  .logoRs img {
    width: 217px;
    margin-left: 5px;
  }

  .navbar-toggler {
    background: #fff;
  }

  .welCome {
    font-size: 29px;
    color: #ffffff;
    font-weight: bold;
    margin: 15% 0 0 0;
    line-height: 36px;
  }

  .navbar-toggler-icon {
    display: inline-block;
    width: 19px;
    height: 22px;
  }

  .dearUser {
    font-size: 12px;
    color: #ffff;
    margin: 7px 0;
  }

  .loginBtn {
    padding: 1px 7px;
    border: 3px solid #f3f3f3;
    font-size: 15px;
  }

  .navBarright a {
    padding: 10px 25px !important;
    color: #cfcfcf !important;
    font-size: 15px;
    transition: 1s;
    border-bottom: 1px solid #f9f9f933;
  }

}

@media (min-width:992px) and (max-width:1280px) {
  .welCome {
    font-size: 31px;
    color: #ffffff;
    font-weight: bold;
    margin: 10% 0 0 0;
    line-height: 50px;
  }

  .logoRs img {
    width: 232px;
    margin-left: 5px;
  }
}