/* fonts css start */
body {
  font-family: 'Poppins';
}

@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-ExtraBold.woff2') format('woff2'),
      url('./fonts/Poppins-ExtraBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-Bold.woff2') format('woff2'),
      url('./fonts/Poppins-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-Black.woff2') format('woff2'),
      url('./fonts/Poppins-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-BlackItalic.woff2') format('woff2'),
      url('./fonts/Poppins-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-ExtraBoldItalic.woff2') format('woff2'),
      url('./fonts/Poppins-ExtraBoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-BoldItalic.woff2') format('woff2'),
      url('./fonts/Poppins-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-ExtraLight.woff2') format('woff2'),
      url('./fonts/Poppins-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-Light.woff2') format('woff2'),
      url('./fonts/Poppins-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-Italic.woff2') format('woff2'),
      url('./fonts/Poppins-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-LightItalic.woff2') format('woff2'),
      url('./fonts/Poppins-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-ExtraLightItalic.woff2') format('woff2'),
      url('./fonts/Poppins-ExtraLightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-MediumItalic.woff2') format('woff2'),
      url('./fonts/Poppins-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-SemiBold.woff2') format('woff2'),
      url('./fonts/Poppins-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-Thin.woff2') format('woff2'),
      url('./fonts/Poppins-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-Regular.woff2') format('woff2'),
      url('./fonts/Poppins-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-SemiBoldItalic.woff2') format('woff2'),
      url('./fonts/Poppins-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-ThinItalic.woff2') format('woff2'),
      url('./fonts/Poppins-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-Medium.woff2') format('woff2'),
      url('./fonts/Poppins-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}





/* fonts css end */
.logoRs img {
  width: 350px;
  margin-left: 5px;
}

.Login {
  background: linear-gradient(90deg, rgb(240 242 245 / 17%) 0%, rgb(0 0 0) 0%, rgb(2 2 41) 100%);
  width: 100%;
  position: relative;
  min-height: 100vh;
}

.logoComp img {
  max-width: 100%;
}

.logoComp {
  border-radius: 50%;
  padding: 10px;
  width: 55px;
  height: 55px;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;

}

.hrmsTex {
  color: #ff1302;
}

.welCome {
  font-size: 45px;
  color: #ffffff;
  font-weight: bold;
  margin: 20% 0 0 0;
  line-height: 50px;
}

.dearUser {
  font-size: 16px;
  color: #ffff;
  margin: 15px 0;
}

.animateText {
  font-size: 16px;
  color: #ffff;
  margin: 30px 0;
  width: 300px;
}

.hrmsIcon {
  position: absolute;
  left: 0%;
  width: 20%;
  bottom: 5%;
  opacity: 0.1;
  z-index: 1;
}

.hrmsIcon2 {
  position: absolute;
  right: 0%;
  width: 22%;
  bottom: 0%;
  opacity: 0.1;
  z-index: 1;
}

.loginBtn img {
  width: 40px;
}

.loginBtn {
  background-color: #ffffff;
  background-image: -ms-linear-gradient(0deg, #304ffe 0%, #7a04ff 100%);
  color: #000000 !important;
  border-radius: 44px;
  padding: 1px 17px;
  border: 3px solid #f3f3f3;
  display: inline-block;
  cursor: pointer;
  font-size: 23px;
}

.colLoginSec {
  position: static;
  z-index: 5;
}

.bannerCont {
  text-align: right;
}

.bannerCont img {
  max-width: 90%;
}

.carousel-indicators {
  display: none !important;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: none !important;
}

.vert-move {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}

.vert-move {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-10px);
  }
}

.Header {
  position: sticky;
  top: 0;
  z-index: 99;
}

.headersTops {
  background: linear-gradient(90deg, rgb(240 242 245 / 17%) 0%, rgb(0 0 0) 0%, rgb(2 2 41) 100%);
  padding: 0;
}

.navBarright a {
  padding: 20px 25px !important;
  color: #cfcfcf !important;
  font-size: 15px;
  transition: 1s;
}

.navBarright a:hover {
  background: #ff1302;
  transition: 1s;
}

.logoBgs {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rsplText {
  color: #dbdada;
  margin-left: 5px;
}

.someFeatures {
  text-align: center;
  padding: 50px 0;
  background: url("../public/imgs/fbgs.jpg");
  background-size: cover;
}

.someFeatures h1 {
  font-weight: bold;
}

.mainDiv {
  width: 350px;
  padding: 10px;
  background: #fff;
  border-radius: 30px;
  position: relative;
  border: 2px solid #1d3e6f02;
  text-align: left;
  padding-left: 80px;
  margin: 50px 0;
}

.iconsBox {
  position: absolute;
  width: 90px;
  height: 90px;
  background: #ffffff;
  border-radius: 50%;
  border: 10px double #ade2fd;
  left: -29px;
  top: -12px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.iconsBox img {
  max-width: 90%;
}

.headingTxt {
  font-weight: bold;
  text-transform: uppercase;
}

.runingText {
  font-size: 13px;
}

.dataFeatures {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.btn1 {
  background-image: linear-gradient(345deg, #ffffff 0%, #5fc7fb 100%);
}

.footer {
  background: linear-gradient(90deg, rgb(240 242 245 / 17%) 0%, rgb(6 20 37) 0%, rgb(9 9 43) 100%);
  padding: 5px;
  text-align: center;
  color: #fff;
}

.testimonial {
  margin: 0 20px 50px;
}

.testimonial .pic {
  display: inline-block;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  margin: 0 15px 15px 0;
}

.testimonial .pic img {
  width: 100%;
  height: auto;
  border-radius: 50%;
}

.testimonial .testimonial-profile {
  display: inline-block;
  position: relative;
  top: 15px;
}

.testimonial .title {
  display: block;
  font-size: 20px;
  font-weight: 600;
  color: #2f2f2f;
  text-transform: capitalize;
  margin: 0 0 7px 0;
}

.testimonial .post {
  display: block;
  font-size: 14px;
  color: #5d7aa7;
}

.testimonial .description {
  padding: 20px 22px;
  background: #1f487e;
  font-size: 15px;
  color: #fff;
  line-height: 25px;
  margin: 0;
  position: relative;
}

.testimonial .description:before,
.testimonial .description:after {
  content: "";
  border-width: 18px 0 0 18px;
  border-style: solid;
  border-color: #5d7aa7 transparent transparent;
  position: absolute;
  bottom: -18px;
  left: 0;
}

.testimonial .description:after {
  border-width: 18px 18px 0 0;
  left: auto;
  right: 0;
}

.owl-theme .owl-controls {
  margin-top: 10px;
  margin-left: 30px;
}

.owl-theme .owl-controls .owl-buttons div {
  opacity: 0.8;
  background: #fff;
}

.owl-prev:before,
.owl-next:before {
  content: "\f053";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 20px;
  color: #1f487e;
}

.owl-next:before {
  content: "\f054";
}

.loginSect {
  background: #000202a1;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}

.innerCont {
  width: 800px;
  background: #fff;
  margin: 10px;
  border-radius: 10px;
  position: relative;
}

.innerCont {
  display: grid;
  grid-template-columns: 10fr 10fr;
}

.fristCol {
  padding: 68px 0;
  text-align: center;
}

.fristCol img {
  max-width: 90%;
}

.loginForm {
  background: #f7f7f7;
  padding: 60px 15px;
  border-radius: 0 10px 10px 0;
}

.imgBox {
  width: 100px;
  height: 100px;
  background: #eeeeee;
  border: 1px solid #bebebe;
  margin: 5px auto;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginAcct {
  font-weight: bold;
  color: #292727;
  font-size: 18px;
  text-align: center;
}

.buttonSect {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}

.forGot {
  color: #304ffe;
  cursor: pointer;
}

.imgBox svg {
  width: 119px !important;
  height: 117px !important;
  color: #999999;
  margin: 20px 0 0;
}

.closeBtns {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  width: 30px !important;
  height: 30px !important;
}

.bott_1 img {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 99;
  max-width: 350px;
}

.power {
  position: absolute;
  left: 0px;
  bottom: 0px;
  color: #e1e1e1;
  z-index: 99;
  font-size: 14px;
  background: #061126;
  padding: 8px 37px;
  border-radius: 0 50px 0 0;
  border-top: 2px solid #ffffff08;
}

.featres {
  border-radius: 50%;
  width: 47px;
}

.footRow {
  background: url("../public/imgs/landingpgB2.png");
  display: flex;
  z-index: 999;
  color: #fff;
  position: absolute;
  right: 23px;
  bottom: 5px;
  width: 150px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.bgF {
  position: absolute;
  right: 0;
  bottom: 0;
  max-width: 250px;
}
.ftext{
  font-weight: 500;
  font-size: 16px;
}
.background{
  background: url('../public/imgs/floor.png');
  position: absolute;
  background-size: cover;
  width: 100%;
  height: 100%;
  bottom: 0;
  opacity: 0.1;
  right: 0;
}
.notFound{
  text-align: center;
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(to right, #243949 0%, #517fa4 100%);
  padding: 5vw 0;
}
.btnSec{
  text-align: center;
}
.notFound img{
  max-width: 450px;
}
.buttonWithIcons{
  display: flex;
  justify-content: flex-start;
  color: #fff;
}
body {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}