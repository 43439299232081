.emplheaderBck {
    background-image: linear-gradient(to top, #264260 0%, #2f5675 100%);
    position: sticky;
    top: 0;
    z-index: 98;
    padding: 3px;
}

.loginDetailes {
    background: #fff;
    padding: 10px;
    position: fixed;
    right: 0;
    top: 10px;
    z-index: 9999;
    height: 100%;
}

.rowHeaders {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5px;
    position: sticky;
    top: 0;
    z-index: 999999;
}

.empldashBoardText {
    color: #fff;
    font-size: 17px;
}

.userPro {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 5px 0 0;
    border: 2px solid #fff;
}

.userPro img {
    max-width: 100%;
}

.emplunames {
    background: none !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none !important;
    color: #ffffff;

}

.unames:hover {
    color: #000000;
}

.dropItem {
    width: 100%;
}

.dropItem a {
    border-bottom: 1px solid #e6e6e6;
    padding: 8px;
}

.dropItem {
    padding: 0;
}

body {
    overflow: auto;
}

.notiBox {
    position: relative;
    color: #ffffff;
    margin: 8px 20px 0 20px;
    cursor: pointer;
}

.dropDivs {
    background: #e7e9f6;
    padding: 20px;
    min-height: 94vh;
}

.empleftBar {
    position: fixed;
    left: 0;
    top: 46px;
    background: #303549;
    width: 246px;
    height: 100%;
    transition: 1s;
    overflow: auto;
}

.employee_menu .accordion-button {
    background: #264260;
    color: #fff;
    white-space: nowrap;
}

.employee_menu,
.accordion-item {
    border: none;
    border-bottom: 1px solid #ffffff29;
}

.btn_div {
    font-size: 14px !important;
}

.employBtns {
    padding: 13px 20px;
    border-bottom: 1px solid #e1e1e1;
    cursor: pointer;
    font-size: 14px;
}

.acc_btns {
    background: #3b3b3b !important;
}

.employee_menu .accordion-button:hover {
    background: #111111 !important;
}

.listDet {
    padding: 0;
    margin: 0;
}

.listDet li {
    list-style: none;
    color: #fff;
    font-size: 14px;
    border-bottom: 1px solid #99999926;
    background: #102b47;
    padding: 15px 10px;
    cursor: pointer;
}

.listDet li:hover {
    background: #030303;
}

.headBtns::after {
    content: none !important;
}

.noneedArrow .accordion-button::after {
    content: none !important;
}

.emp_rightcolm {
    border: 1px solid #efe8e8;
    margin-left: 241px;
    min-height: 89vh;
    position: relative;
}

.eventNoti {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 13px;
    padding: 3px;
    background: #3b3737;
    color: #fff;
}

.content {
    width: 100%;
    padding: 0px 0;
    position: relative;
    background: #ffffff;
    text-align: center;
    border-radius: 3px;

}

.men_box {
    background: #ffffff;
    width: 100%;
}

.events-container {
    overflow-y: scroll;
    height: 100%;
    float: right;
    margin: 0px auto;
    font: 13px Helvetica, Arial, sans-serif;
    display: inline-block;
    padding: 0 10px;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
}

.events-container:after {
    clear: both;
}

.event-card {
    padding: 20px 0;
    width: 350px;
    margin: 20px auto;
    display: block;
    background: #fff;
    border-left: 10px solid #52A0FD;
    border-radius: 3px;
    box-shadow: 3px 8px 16px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    -moz-box-shadow: 3px 8px 16px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    -webkit-box-shadow: 3px 8px 16px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.event-count,
.event-name,
.event-cancelled {
    display: inline;
    padding: 0 10px;
    font-size: 1rem;
}

.event-count {
    color: #52A0FD;
    text-align: right;
}

.event-name {
    padding-right: 0;
    text-align: left;
}

.event-cancelled {
    color: #FF1744;
    text-align: right;
}

.calendar-container {
    position: relative;
    margin: 0px auto;
    height: 100%;
    background: #fff;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
    max-width: 100%;
    overflow: auto;
}

.calendar-container:after {
    clear: both;
}

.calendar {
    display: table;
    width: 100%;
}

.year-header {
    /* background: #dfdfdf; */
    height: 40px;
    text-align: center;
    position: relative;
    color: #000000;
    border-top-left-radius: 3px;
}

.year-header span {
    display: inline-block;
    font-size: 20px;
    line-height: 40px;
}

.left-button,
.right-button {
    cursor: pointer;
    width: 28px;
    text-align: center;
    position: absolute;
}

.left-button {
    left: 0;
    -webkit-border-top-left-radius: 5px;
    -moz-border-radius-topleft: 5px;
    border-top-left-radius: 5px;
}

.right-button {
    right: 0;
    top: 0;
    -webkit-border-top-right-radius: 5px;
    -moz-border-radius-topright: 5px;
    border-top-right-radius: 5px;
}

.left-button:hover {
    background: #3FADFF;
}

.right-button:hover {
    background: #00C1FF;
}

.button {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    font-size: 1rem;
    border-radius: 25px;
    padding: 0.65rem 1.9rem;
    transition: .2s ease all;
    color: white;
    border: none;
    box-shadow: -1px 10px 20px #9BC6FD;
    background: #52A0FD;
    background: -moz-linear-gradient(left, #52A0FD 0%, #00C9FB 80%, #00C9FB 100%);
    background: -webkit-linear-gradient(left, #52A0FD 0%, #00C9FB 80%, #00C9FB 100%);
    background: linear-gradient(to right, #52A0FD 0%, #00C9FB 80%, #00C9FB 100%);
}

#cancel-button {
    box-shadow: -1px 10px 20px #FF7DAE;
    background: #FF1744;
    background: -moz-linear-gradient(left, #FF1744 0%, #FF5D95 80%, #FF5D95 100%);
    background: -webkit-linear-gradient(left, #FF1744 0%, #FF5D95 80%, #FF5D95 100%);
    background: linear-gradient(to right, #FF1744 0%, #FF5D95 80%, #FF5D95 100%);
}

#add-button {
    display: block;
    position: absolute;
    right: 20px;
    bottom: 20px;
}

#add-button:hover,
#ok-button:hover,
#cancel-button:hover {
    transform: scale(1.03);
}

#add-button:active,
#ok-button:active,
#cancel-button:active {
    transform: translateY(3px) scale(.97);
}

.days-table {
    border-collapse: separate;
    text-align: center;
    width: 100%;
}

.dates-table {
    border-collapse: separate;
    text-align: center;
    width: 100%;
}

.months-table {
    border-collapse: separate;
    text-align: center;
    width: 100%;
}

.day {
    height: 26px;
    width: 26px;
    padding: 0 10px;
    line-height: 26px;
    border: 2px solid transparent;
    text-transform: uppercase;
    font-size: 90%;
    color: #9e9e9e;
}

.month {
    cursor: default;
    height: 26px;
    width: 26px;
    padding: 0 2px;
    padding-top: 10px;
    line-height: 26px;
    text-transform: uppercase;
    font-size: 11px;
    color: #9e9e9e;
    transition: all 250ms;
}

.active-month {
    font-weight: bold;
    font-size: 14px;
    color: #16befc;
    /* text-shadow: 0 1px 4px RGBA(255, 50, 120, .8); */
    /* border: 1px solid #42a8fd; */
}

.month:hover {
    color: #16befc;
}

.table-date {
    cursor: default;
    color: #2b2b2b;
    font-size: 15px;
    padding: 5px;
    text-align: center;
    border-radius: 50%;
    border: 2px solid transparent;
    transition: all 250ms;
}

.table-date:not(.nil):hover {
    border-color: #022ff7;
}

.event-date {
    border-color: #52A0FD;
    box-shadow: 0 2px 8px RGBA(130, 180, 255, .9);
}

.active-date {
    color: #131212;
    border: 1px solid #0392bf;

}

.event-date.active-date {
    background: #52A0FD;
    box-shadow: 0 2px 8px RGBA(130, 180, 255, .9);
}

.dialog {
    z-index: 5;
    background: #fff;
    position: absolute;
    width: 415px;
    height: 500px;
    left: 387px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    display: none;
    border-left: 1px #aaa solid;
}

.dialog-header {
    margin: 20px;
    color: #333;
    text-align: center;
}

.form-container {
    margin-top: 25%;
}

.form-label {
    color: #333;
}

.input {
    border: none;
    background: none;
    border-bottom: 1px #aaa solid;
    display: block;
    margin-bottom: 50px;
    width: 200px;
    height: 20px;
    text-align: center;
    transition: border-color 250ms;
}

.input:focus {
    outline: none;
    border-color: #00C9FB;
}

.error-input {
    border-color: #FF1744;
}

.emplologHeader {
    background: #e9e9e9;
    padding: 10px;
}

.date_text {
    margin: 5px 10px;
    font-size: 15px;
}

.details {
    background: #fff;
    margin: 20px 0;
}

.text_heaers {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #e2e2e2;
    padding: 5px 10px;
    background: #dfdfdf42;
}

.date_present {
    font-size: 15px;
    padding: 10px 0 10px 17px;
}

.rowDetails {
    display: flex;
    justify-content: flex-start;
    padding: 5px 20px;
    font-size: 13px;
    border-bottom: 1px solid #ebebeb;
}

.rowDetails div {
    width: 250px;
}

.showMultyDetails {
    height: 15vw;
    overflow-y: auto;
}

.header_month {
    padding: 10px 0;
    text-align: left;
    font-weight: bold;
    /* border-bottom: 1px solid #e7e7e7; */
}

.btn_div svg {
    padding: 2px;
    width: 28px;
    height: 28px;
}

.monthlyAtte {
    background: #fff;
    height: 100%;
    margin-bottom: 25px;
    border-radius: 17px;
}

.colorBox {
    width: 10px;
    height: 10px;
    background: #ffaf03fb;
}

.emplmenuIcons {
    position: fixed;
    left: 3px;
    top: 14px;
    z-index: 99;
    width: 27px !important;
    height: 28px !important;
    cursor: pointer;
    color: #fff;
}

#offBtn {
    display: none;
}

.empandroidItem {
    display: none;
}

.pendingLeaves {
    border: 1px solid #ededed;
    padding: 20px;
    text-align: center;
}

.pendingLeaves img {
    width: 30%;

}

.employeeNoti_logHeader {
    background: #636363;
    color: #fff;
    padding: 13px;
}

.imgpunch {
    text-align: center;
}

.imgpunch img {
    width: 90%;
}

.rowSect {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
}

.punchBtn {
    color: #282936;
    padding: 13px 5px 10px 45px;
    border-radius: 0;
    position: relative;
    transition: all 0.2s ease 0s;
    z-index: 1;
    font-size: 13px;
    border: 1px solid #e1e1e1;
    border-radius: 50px;
    width: 49%;
    white-space: nowrap;
    background: #28293630;
    cursor: pointer;
}

.punchBtn:hover {
    color: #fff;
    border-color: transparent;
}

.punchBtn:before,
.punchBtn:after {
    color: #fff;
    content: "→";
    line-height: 20px;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 6px;
    left: 6px;
    z-index: 0;
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    font-size: 32px;
}

.punchBtn:after {
    background-color: #282936;
    content: "";
    width: 43px;
    height: 43px;
    border-radius: 50px;
    position: absolute;
    top: 0px;
    left: 0;
    z-index: -1;
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
}

.punchBtn:hover:after {
    background-color: #8e44ad;
    width: 100%;
}

.punchBtn.orange:hover:after {
    background-color: #EE5A24;
    width: 100%;
}

.punchBtn.blue:hover:after {
    background-color: #273c75;
    width: 100%;
}

.punchBtn.magenta:hover:after {
    background-color: #db2b63;
    width: 100%;
}

.emplMarkAttenHeader {
    background: #385883;
    padding: 12px;
    color: #fff;
}

.show_tabData {
    background: #fff;
}

.menHeading {
    padding: 6px;
    background: #f7f7f7;
    display: grid;
    grid-template-columns: 2fr 10fr;
    align-items: center;
}

.menHeading_2 {
    padding: 15px 5px;
    background: #f7f7f7;
    display: grid;
    grid-template-columns: 10fr 10fr 2fr;
    align-items: center;
}

.menHeading_3 {
    padding: 15px 0;
    background: #f7f7f7;
    display: grid;
    grid-template-columns: 2fr 10fr;
    align-items: center;
}

.menHeading_4 {
    padding: 15px 0;
    background: #f7f7f7;
    display: grid;
    grid-template-columns: 10fr 10fr;
    align-items: center;
}

.secondCol {
    display: flex;
    justify-content: end;
    align-items: center;
    flex-wrap: wrap;
}

.show_tabData table {
    white-space: nowrap;
    font-size: 14px;
}

.table_conte {
    overflow: auto;
    margin: 15px;
}

.dateSese {
    margin: 10px;
    display: flex;
    justify-content: center;
}

.field {
    margin: 0 12px;
}

.printHeading {
    font-weight: bold;
}

.active_menu {
    background: #23243b !important;
}

.empProBack {
    background: #efefef;
    background-image: linear-gradient(-180deg, rgba(255, 255, 255, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%);
    background-blend-mode: lighten;
    padding: 20px;
}

.userProDP {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    border: 7px double #698097
}

.userProDP img {
    max-width: 100%;
}

.empProBack {
    display: grid;
    grid-template-columns: 3fr 10fr;
    align-items: center;
}

.row_dete {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #77777724;
    padding: 8px 0;
    font-size: 15px;
}

.fristColm {
    width: 150px;
}

.footer_pro {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0 0 0;
    font-size: 15px;

}

.footer_pro svg {
    color: #224e5d;
}

.userIDData {
    height: 70vh;
    overflow: auto;
    /* border: 1px solid #f1f1f1; */
    padding: 10px;
    border-radius: 5px;

}

.searchBox {
    border: none;
    border-bottom: 1px solid #dbdbdb;
    margin: 10px 0;
    border-radius: 0;
}

.searchBox:focus {
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.contactDetails {
    margin: 15px 0;
    font-size: 15px;
    background: #e4e4e4;
    padding: 5px;
}

.user_deAcc button {
    padding: 9px;
    background: #e5e5e5;
    font-size: 14px;
}

.inputesRow {
    margin: 15px 0;
}

.inputesRow input {
    width: 100%;
}

.report_back {
    /* background: url('../../../../public/imgs/employee/and.jpg'); */
}

.trHead {
    background: #52A0FD !important;
    white-space: nowrap;
}

.emplAddTbls {
    position: relative;
}

.summaryData button {
    border: 1px solid #f1f1f1;
    background: #f3f3f3;
}

.summaryData .accordion-body {
    background: #ffffff;
    border: 1px solid #99999940;
}

.rowSum {
    display: grid;
    grid-template-columns: 11fr 10fr;
    border-bottom: 1px solid #d1d1d1;
    margin: 10px 0;
    font-size: 14px;
}

.boxShadow {
    background: #fbfbfb;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ededed;
    margin: 10px 0;
}

.bodyData_summ {
    height: 350px;
    overflow: auto;
}

.dataDiv {
    margin: 20px 0 0 0;
}

.leftSideCol {
    border: 1px solid #f1f1f1;
    padding: 10px;
    background: #f8f8f8;
    margin: 10px;
}

.toHeaderLeaveAcc {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f5f5f5;
    padding: 6px;
    border: 1px solid #d6d6d6;
    margin: 10px 0px 0 0;
}

.bothColm {
    display: flex;
}

.shootMeHeader {
    background: #f1f1f1;
    padding: 10px;
}

.main_wrapper {
    background: #fff;
    padding: 15px;
    border-radius: 15px;
    margin: 10px 0;
}

.headerRowSelection {
    display: flex;
    justify-content: center;
    align-items: center;
}

.inputSelect_att {
    width: 98%;
    margin: 10px 0;
}

.monthAnd_years {
    font-size: 15px;
    margin: 10px 0;
}

.years_color {
    color: #088559;
}

.days_box {
    text-align: center;
    background: #d3f4ff;
    padding: 5px;
}

.attend_btl {
    text-align: center;
    font-size: 15px;
}

.date_string {
    border: 2px solid #008923ab;
    width: 27px;
    height: 27px;
    margin: 0 auto;
    border-radius: 50%;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.date_ShiftShe {
    border: 2px solid #f7f7f7ab;
    width: 27px;
    height: 27px;
    margin: 0 auto;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ab {
    border: 2px solid #f73c03;
}

.attend_btl {
    white-space: nowrap;
}

.daysRow th {
    padding: 0;
}

.trRowAtten {
    background: #d3f4ff !important;
}

.trRowAtten_2 {
    background: #ececec !important;
}

.offf {
    border: 2px solid #ffaf03fb;
}

.rowForcolm {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0;
    font-size: 12px;
}

.present {
    background: #008923ab;
}

.absent {
    background: #f00606ab;
}

.colms {
    display: flex;
    justify-content: center;
    align-items: center;
}

.headerYourAtt {
    display: flex;
    justify-content: space-between;
}

.line_process {
    width: 100%;
    height: 5px;
    background: #088559;
    position: relative;
}

.redLine {
    height: 5px;
    background: #f82500;
    position: absolute;
    left: 0;
    top: 0;
}

.renaimingTime img {
    width: 39px;
}

/* media query */

@media (min-width: 20px) and (max-width: 767px) {
    .empleftBar {
        position: fixed;
        left: -250px;
        /* top: 50px; */
        width: 246px;
        height: 100%;
        z-index: 9;
    }

    .empandroidItem {
        display: block;
    }

    .emp_rightcolm {
        margin-left: 0;
    }

    .dropDivs {
        padding: 0px;
    }

    .showMultyDetails {
        height: auto;
    }

    .header_month {
        padding: 5px;
        font-size: 12px;
    }

    .punchBtn {
        color: #282936;
        padding: 13px 5px 10px 45px;
        font-size: 11px;
    }

    .menHeading {
        display: block;
    }

    .secondCol {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .printHeading {
        text-align: center;
    }

    .empProBack {
        display: block;
    }

    .fristColm {
        width: 124px;
    }

    .userProDP {
        margin: 0 auto;
    }

    .footer_pro {
        display: block;
        margin: 5px 0 0 0;
        font-size: 12px;
    }

    .row_dete {
        display: block;
        padding: 5px 0;
        font-size: 12px;
    }

    .show_tabData {
        padding: 0;
    }
}